import React, { useEffect } from "react";
import styled from "styled-components";

import IntroPage from "./Intro";
import ClassPage from "./ClassPage";
import StructurePage from "./StructurePage";
import ShapePage from "./ShapePage";
import SculpturePage from "./SculpturePage";
import FinalPage from "./FinalPage";
import { ClassType, Entry } from "./types";
import { ActionType, EntryContext } from "./context";

enum Page {
  Intro,
  Shape,
  ClassSize,
  Structure,
  // Sculpture,
  FinalPage,
}

export default function Pages() {
  const [currentPage, setCurrentPage] = React.useState<Page>(Page.Intro);
  const [entries, setEntries] = React.useState<Entry[]>([]);
  const { dispatch } = React.useContext(EntryContext);

  const next = () => {
    setCurrentPage(currentPage + 1);
  };

  const back = () => {
    setCurrentPage(currentPage - 1);
  };

  const submit = (newEntry: Entry) => {
    setEntries(entries.concat(newEntry));
    dispatch({ type: ActionType.ClearEntry });
    setCurrentPage(Page.Intro);
  };

  const pageComponent = () => {
    switch (currentPage) {
      case Page.Intro:
        return <IntroPage next={next} entries={entries} />;
      case Page.Shape:
        return <ShapePage next={next} back={back} />;
      case Page.ClassSize:
        return <ClassPage next={next} back={back} />;
      case Page.Structure:
        return <StructurePage next={next} back={back} />;
      //   case Page.Sculpture:
      //   return <SculpturePage {...navigationProps} />;
      case Page.FinalPage:
        return <FinalPage back={back} submit={submit} />;
    }
  };

  return <div>{pageComponent()}</div>;
}
