import { cpuUsage } from "process";
import React from "react";
import styled from "styled-components";
import { ActionType, EntryContext } from "./context";
import NavigationButtons from "./NavigationButtons";
import {
  PageProps,
  StructureType,
  ColumellaeForm,
  ColumellaeShape,
  ExineStratification,
  StructureIndex,
} from "./types";

const StyledSelect = styled.select`
  margin-left: 10px;
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px;
  border: 2px forestgreen dashed;
`;

export default function StructurePage(props: PageProps) {
  const [structureType, setStructureType] = React.useState<StructureType>();
  const [columellaeForm, setColumellaeForm] = React.useState<ColumellaeForm>();
  const [columellaeShape, setColumellaeShape] = React.useState<ColumellaeShape>();
  const [exineStratification, setExineStratification] = React.useState<ExineStratification>();
  const [arci, setArci] = React.useState<boolean>();
  const [endocracks, setEndocracks] = React.useState<boolean>();
  const [cingulum, setCingulum] = React.useState<boolean>();
  const [maxExineThickness, setMaxExine] = React.useState<number>();
  const [minExineThickness, setMinExine] = React.useState<number>();
  const [maxPerisporeThickness, setMaxPerispore] = React.useState<number>();
  const [tectumThickness, setTectumThick] = React.useState<number>();
  const [structureIndex, setStructureIndex] = React.useState<StructureIndex>();

  const { entry, dispatch } = React.useContext(EntryContext);

  const allStructureTypes = Object.values(StructureType);
  const allColumellaeTypes = Object.values(ColumellaeForm);
  const allColumellaeShapes = Object.values(ColumellaeShape);
  const allExineStrats = Object.values(ExineStratification);
  const allStructureIndeces = Object.values(StructureIndex);

  console.log(entry);

  return (
    <div>
      <h1>Structure Features</h1>
      <Section>
        <div>
          <h3>Structure Type</h3>
        </div>

        <StyledSelect
          value={structureType}
          onChange={(event) => setStructureType(event.target.value as StructureType)}
        >
          <option></option>
          {allStructureTypes.map((structureType, i) => (
            <option key={i}>{structureType}</option>
          ))}
        </StyledSelect>
      </Section>

      {structureType && structureType !== "intec" && (
        <div>
          <Section>
            <h3>Describe the columellae</h3>
            <StyledSelect
              value={columellaeForm}
              onChange={(event) => setColumellaeForm(event.target.value as ColumellaeForm)}
            >
              <option></option>
              {allColumellaeTypes.map((columellaeForm, i) => (
                <option key={i}>{columellaeForm}</option>
              ))}
            </StyledSelect>
          </Section>

          <Section>
            <h3>Enter Columellae shape</h3>
            <StyledSelect
              value={columellaeShape}
              onChange={(event) => setColumellaeShape(event.target.value as ColumellaeShape)}
            >
              <option></option>
              {allColumellaeShapes.map((columellaeShape, i) => (
                <option key={i}>{columellaeShape}</option>
              ))}
            </StyledSelect>
          </Section>
        </div>
      )}

      <Section>
        <div>
          <h3>Exine Stratification</h3>
          <p>Describe the number and relative thickness of layers seen in optical section</p>
        </div>
        <StyledSelect
          value={exineStratification}
          onChange={(event) => setExineStratification(event.target.value as ExineStratification)}
        >
          <option></option>
          {allExineStrats.map((exineStratification, i) => (
            <option key={i}>{exineStratification}</option>
          ))}
        </StyledSelect>
      </Section>
      <Section>
        <div>
          <h3> Are Arci present?</h3>
          <p>Arci: thickened bnads of extexine that cross the surface</p>
        </div>
        <input type="checkbox" checked={arci} onChange={() => setArci(!arci)}></input>
      </Section>
      <Section>
        <div>
          <h3>Are endocracks present?</h3>
          <p>Endocracks: fissures in the endexine</p>
        </div>
        <input
          type="checkbox"
          checked={endocracks}
          onChange={() => setEndocracks(!endocracks)}
        ></input>
      </Section>
      <Section>
        <div>
          <h3>Is a Cingulum present?</h3>
          <p>Cingulum: a flange-like thickening of the exospore at the equator of a spore</p>
        </div>
        <input type="checkbox" checked={cingulum} onChange={() => setCingulum(!cingulum)}></input>
      </Section>
      <Section>
        <div>
          <h3>Enter the maximum thickness of exine</h3>
          <input
            type="number"
            value={maxExineThickness}
            onChange={(event) => setMaxExine(parseInt(event.target.value))}
          ></input>
        </div>
        <div>
          <h3>Enter the minimum thickness of exine</h3>
          <input
            type="number"
            value={minExineThickness}
            onChange={(event) => setMinExine(parseInt(event.target.value))}
          ></input>
        </div>
      </Section>
      <Section>
        <div>
          <h3>Enter the maximum thickness of Perispore</h3>
          <input
            type="number"
            value={maxPerisporeThickness}
            onChange={(event) => setMaxPerispore(parseInt(event.target.value))}
          ></input>
        </div>
        <div>
          <h3>Enter the thickness of Tectum</h3>
          <input
            type="number"
            value={tectumThickness}
            onChange={(event) => setTectumThick(parseInt(event.target.value))}
          ></input>
        </div>
      </Section>
      <Section>
        <div>
          <h3>Structure Index</h3>
        </div>

        <StyledSelect
          value={structureIndex}
          onChange={(event) => setStructureIndex(event.target.value as StructureIndex)}
        >
          <option></option>
          {allStructureIndeces.map((structureIndex, i) => (
            <option key={i}>{structureIndex}</option>
          ))}
        </StyledSelect>
      </Section>

      <NavigationButtons
        next={() => {
          dispatch({
            type: ActionType.SetStructure,
            data: {
              structure: structureType,
              columellae: columellaeForm,
              columellaeShape: columellaeShape,
              exineStratification: exineStratification,
              arci: arci,
              endocracks: endocracks,
              cingulum: cingulum,
              maxExineThickness: maxExineThickness,
              minExineThickness: minExineThickness,
              maxPerisporeThickness: maxPerisporeThickness,
              tectumThickness: tectumThickness,
              structureIndex: structureIndex,
            },
          });

          props.next?.();
        }}
        back={() => {
          props.back?.();
        }}
      />
    </div>
  );
}
