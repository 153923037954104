import React from "react";
import styled from "styled-components";
import { ActionType, EntryContext } from "./context";

import NavigationButtons from "./NavigationButtons";
import ShapeTables from "./ShapeTables";
import { PageProps, Symmetry, View } from "./types";

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px;
  border: 2px forestgreen dashed;
`;

const ShapeTableSection = styled(Section)`
  justify-content: center;
`;

export default function ShapePage(props: PageProps) {
  const { entry, dispatch } = React.useContext(EntryContext);

  const allViews = Object.values(View);
  const allSymmetries = Object.values(Symmetry);

  return (
    <div>
      <h1>Shape</h1>

      <h3>Please select the sample view and symmetry to see corresponding shape tables</h3>
      <Section>
        <h3>View</h3>
        <select
          onChange={(event) =>
            dispatch({ type: ActionType.SetView, data: { view: event.target.value } })
          }
        >
          <option></option>
          {allViews.map((view, i) => (
            <option key={i}>{view}</option>
          ))}
        </select>

        <h3>Symmetry</h3>
        <select
          onChange={(event) =>
            dispatch({ type: ActionType.SetSymmetry, data: { symmetry: event.target.value } })
          }
        >
          <option></option>
          {allSymmetries.map((symmetry, i) => (
            <option key={i}>{symmetry}</option>
          ))}
        </select>
      </Section>

      {entry.view && entry.symmetry && (
        <ShapeTableSection>
          <ShapeTables />
        </ShapeTableSection>
      )}

      <NavigationButtons {...props} />
    </div>
  );
}
