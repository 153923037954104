import React, { useContext } from "react";
import styled from "styled-components";
import { EntryContext } from "./context";

import { ClassType, Entry, PageProps, Shape, StructureType } from "./types";

const Table = styled.table`
  border: 1px solid forestgreen;
  width: 100%;
  text-align: center;
  text-transform: capitalize;

  th {
    font-weight: bold;
    background: lightgrey;
    font-size: 20px;
  }

  th,
  td {
    border: 1px solid forestgreen;
    padding: 10px;
  }
`;

const SubmitButton = styled.button`
  margin-top: 20px;
`;

export default function FinalPage(props: PageProps & { submit?: (newEntry: Entry) => void }) {
  const { entry } = useContext(EntryContext);

  return (
    <div>
      <h1>Submit Entry</h1>

      <Table>
        {Object.entries(entry).map((quality) => {
          // [class, saccate]

          if (!quality[1]) return null;
          return (
            <tr>
              <th>{quality[0]}</th>
              <td>{quality[1]}</td>
            </tr>
          );
        })}
      </Table>

      <SubmitButton onClick={() => props.submit?.(entry)}>Submit</SubmitButton>
    </div>
  );
}
