import React from "react";
import styled from "styled-components";
import { ActionType, EntryContext } from "./context";
import NavigationButtons from "./NavigationButtons";
import { Entry, PageProps } from "./types";

import { CSVLink } from "react-csv";

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px;
  border: 2px forestgreen dashed;
`;

export default function IntroPage(props: PageProps & { entries: Entry[] }) {
  const { entry, dispatch } = React.useContext(EntryContext);

  return (
    <div>
      <h1>Welcome</h1>
      <p>This website is a tool to easily input and save spore and pollen data. </p>
      <img src={require("./images/3CoolSpores.png")}></img>

      {props.entries.length > 0 && (
        <CSVLink data={props.entries} filename={"spore-data.csv"}>
          Click here to download a CSV of all entered entries.
        </CSVLink>
      )}
      <NavigationButtons
        next={() => {
          props.next?.();
        }}
      />
      <p>The convention for data formatting used here is based chiefly on work from Edward J. Cushing in his paper titled "Outline for the Description of Pollen and Spores"(1993) </p>

    </div>
  );
}
