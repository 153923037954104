import { Shape, ShapeTableNumber } from "../types";

export const getShapeImage = (table: ShapeTableNumber, shape: Shape) => {
  switch (table) {
    case 1: //SHAPE TABLE #1
      switch (shape) {
        case Shape.Circular:
          return require("./shapeTable1/1-circular.png");
        case Shape.Angular:
          return require("./shapeTable1/1-angular.png");
        case Shape.InterAngular:
          return require("./shapeTable1/1-inter-angular.png");
        case Shape.SemiAngular:
          return require("./shapeTable1/1-semi-angular.png");
        case Shape.InterSemiAngular:
          return require("./shapeTable1/1-inter-semi-angular.png");
        case Shape.Subangular:
          return require("./shapeTable1/1-subangular.png");
        case Shape.InterSubangular:
          return require("./shapeTable1/1-inter-subangular.png");
        case Shape.Lobate:
          return require("./shapeTable1/1-lobate.png");
        case Shape.InterLobate:
          return require("./shapeTable1/1-inter-lobate.png");
        case Shape.SemiLobate:
          return require("./shapeTable1/1-semi-lobate.png");
        case Shape.InterSemiLobate:
          return require("./shapeTable1/1-inter-semi-lobate.png");
        case Shape.Polygonal:
          return require("./shapeTable1/1-polygonal.png");
        case Shape.InterPolygonal:
          return require("./shapeTable1/1-inter-polygonal.png");
      }
      break;
    case 2: //SHAPE TABLE #2
      switch (shape) {
        case Shape.CircularSpheroidal:
          return require("./shapeTable2/2-spheroidal-circular.png");
        case Shape.EllipticalProlate:
          return require("./shapeTable2/2-prolate-elliptical.png");
        case Shape.EllipticalOblate:
          return require("./shapeTable2/2-oblate-elliptical.png");
        case Shape.RhomboidalSpheroidal:
          return require("./shapeTable2/2-spheroidal-rhomboidal.png");
        case Shape.RhomboidalProlate:
          return require("./shapeTable2/2-prolate-rhomboidal.png");
        case Shape.RhomboidalOblate:
          return require("./shapeTable2/2-oblate-rhomboidal.png");
        case Shape.ApiculateSpheroidal:
          return require("./shapeTable2/2-spheroidal-apiculate.png");
        case Shape.ApiculateProlate:
          return require("./shapeTable2/2-prolate-apiculate.png");
        case Shape.ApiculateOblate:
          return require("./shapeTable2/2-oblate-apiculate.png");
        case Shape.RectangularSpheroidal:
          return require("./shapeTable2/2-spheroidal-rectangular.png");
        case Shape.RectangularProlate:
          return require("./shapeTable2/2-prolate-rectangular.png");
        case Shape.RectangularOblate:
          return require("./shapeTable2/2-oblate-rectangular.png");
        case Shape.ConstrictedEllipticalSpheroidal:
          return require("./shapeTable2/2-spheroidal-constricted-elliptical.png");
        case Shape.ConstrictedEllipticalProlate:
          return require("./shapeTable2/2-prolate-constricted-elliptical.png");
        case Shape.ConstrictedEllipticalOblate:
          return require("./shapeTable2/2-oblate-constricted-elliptical.png");
        case Shape.ConstrictedRectangularSpheroidal:
          return require("./shapeTable2/2-spheroidal-constricted-rectangular.png");
        case Shape.ConstrictedRectangularProlate:
          return require("./shapeTable2/2-prolate-constricted-rectangular.png");
        case Shape.ConstrictedRectangularOblate:
          return require("./shapeTable2/2-oblate-constricted-rectangular.png");
        case Shape.CompressedEllipticalSpheroidal:
          return require("./shapeTable2/2-spheroidal-compressed-elliptical.png");
        case Shape.CompressedEllipticalProlate:
          return require("./shapeTable2/2-prolate-compressed-elliptical.png");
        case Shape.CompressedEllipticalOblate:
          return require("./shapeTable2/2-oblate-compressed-elliptical.png");
        case Shape.DepressedEllipticalSpheroidal:
          return require("./shapeTable2/2-spheroidal-depressed-elliptical.png");
        case Shape.DepressedEllipticalProlate:
          return require("./shapeTable2/2-prolate-depressed-elliptical.png");
        case Shape.DepressedEllipticalOblate:
          return require("./shapeTable2/2-oblate-depressed-elliptical.png");
      }
      break;
    case 3: //SHAPE TABLE #3
      switch (shape) {
        case Shape.BiconvexSpheroidal:
          return require("./shapeTable3/3-spheroidal-biconvex.png");
        case Shape.BiconvexOblate:
          return require("./shapeTable3/3-oblate-biconvex.png");
        case Shape.PlanoConvexSpheroidal:
          return require("./shapeTable3/3-spheroidal-plano-convex.png");
        case Shape.PlanoConvexOblate:
          return require("./shapeTable3/3-oblate-plano-convex.png");
        case Shape.ConcavoConvexSpheroidal:
          return require("./shapeTable3/3-spheroidal-concavo-convex.png");
        case Shape.ConcavoConvexOblate:
          return require("./shapeTable3/3-oblate-concavo-convex.png");
        case Shape.ConvexoPlaneSpheroidal:
          return require("./shapeTable3/3-spheroidal-convexo-plane.png");
        case Shape.ConvexoPlaneOblate:
          return require("./shapeTable3/3-oblate-convexo-plane.png");
        case Shape.ConvexoConcaveSpheroidal:
          return require("./shapeTable3/3-spheroidal-convexo-concave.png");
        case Shape.ConvexoConcaveOblate:
          return require("./shapeTable3/3-oblate-convexo-concave.png");
        case Shape.ConvexoAcuteSpheroidal:
          return require("./shapeTable3/3-spheroidal-convexo-acute.png");
        case Shape.ConvexoAcuteOblate:
          return require("./shapeTable3/3-oblate-convexo-acute.png");
        case Shape.RectangularAcuteSpheroidal:
          return require("./shapeTable3/3-spheroidal-rectangular-acute.png");
        case Shape.RectangularAcuteOblate:
          return require("./shapeTable3/3-oblate-rectangular-acute.png");
      }
      break;
    case 4: //SHAPE TABLE #4
      switch (shape) {
        case Shape.Elliptical:
          return require("./shapeTable4/4-elliptical.png");
        case Shape.ConstrictedElliptical:
          return require("./shapeTable4/4-constricted-elliptical.png");
        case Shape.Rhomboidal:
          return require("./shapeTable4/4-rhomboidal.png");
        case Shape.ConstrictedRectangular:
          return require("./shapeTable4/4-constricted-rectangular.png");
        case Shape.Apiculate:
          return require("./shapeTable4/4-apiculate.png");
        case Shape.CompressedElliptical:
          return require("./shapeTable4/4-compressed-elliptical.png");
        case Shape.Rectangular:
          return require("./shapeTable4/4-rectangular.png");
        case Shape.DepressedElliptical:
          return require("./shapeTable4/4-depressed-elliptical.png");
      }
      break;
    case 5: //SHAPE TABLE #5
      switch (shape) {
        case Shape.Circular:
          return require("./shapeTable5/5-circular.png");
        case Shape.Rectangular:
          return require("./shapeTable5/5-rectangular.png");
        case Shape.Elliptical:
          return require("./shapeTable5/5-elliptical.png");
        case Shape.CompressedElliptical:
          return require("./shapeTable5/5-compressed-elliptical.png");
        case Shape.Rhomboidal:
          return require("./shapeTable5/5-rhomboidal.png");
        case Shape.DepressedElliptical:
          return require("./shapeTable5/5-depressed-elliptical.png");
      }
      break;
    case 6: //SHAPE TABLE #6
      switch (shape) {
        case Shape.CircularSpheroidal:
          return require("./shapeTable6/6-spheroidal-circular.png");
        case Shape.EllipticalProlate:
          return require("./shapeTable6/6-prolate-elliptical.png");
        case Shape.EllipticalOblate:
          return require("./shapeTable6/6-oblate-elliptical.png");
      }
      break;
    case 7: //SHAPE TABLE #7
      switch (shape) {
        case Shape.BiconvexSpheroidal:
          return require("./shapeTable7/7-spheroidal-biconvex.png");
        case Shape.BiconvexOblate:
          return require("./shapeTable7/7-oblate-biconvex.png");
        case Shape.PlanoConvexSpheroidal:
          return require("./shapeTable7/7-spheroidal-plano-convex.png");
        case Shape.PlanoConvexOblate:
          return require("./shapeTable7/7-oblate-plano-convex.png");
        case Shape.ConcavoConvexSpheroidal:
          return require("./shapeTable7/7-spheroidal-concavo-convex.png");
        case Shape.ConcavoConvexOblate:
          return require("./shapeTable7/7-oblate-concavo-convex.png");
        case Shape.ConvexoPlaneSpheroidal:
          return require("./shapeTable7/7-spheroidal-convexo-plane.png");
        case Shape.ConvexoPlaneOblate:
          return require("./shapeTable7/7-oblate-convexo-plane.png");
        case Shape.ConvexoConcaveSpheroidal:
          return require("./shapeTable7/7-spheroidal-convexo-concave.png");
        case Shape.ConvexoConcaveOblate:
          return require("./shapeTable7/7-oblate-convexo-concave.png");
      }
      break;
    case 8: //SHAPE TABLE #8
      switch (shape) {
        case Shape.BiconvexSpheroidal:
          return require("./shapeTable8/8-spheroidal-biconvex.png");
        case Shape.BiconvexProlate:
          return require("./shapeTable8/8-prolate-biconvex.png");
        case Shape.BiconvexOblate:
          return require("./shapeTable8/8-oblate-biconvex.png");
        case Shape.PlanoConvexSpheroidal:
          return require("./shapeTable8/8-spheroidal-plano-convex.png");
        case Shape.PlanoConvexProlate:
          return require("./shapeTable8/8-prolate-plano-convex.png");
        case Shape.PlanoConvexOblate:
          return require("./shapeTable8/8-oblate-plano-convex.png");
        case Shape.ConcavoConvexSpheroidal:
          return require("./shapeTable8/8-spheroidal-concavo-convex.png");
        case Shape.ConcavoConvexProlate:
          return require("./shapeTable8/8-prolate-concavo-convex.png");
        case Shape.ConcavoConvexOblate:
          return require("./shapeTable8/8-oblate-concavo-convex.png");
        case Shape.AcuteConvexSpheroidal:
          return require("./shapeTable8/8-spheroidal-acute-convex.png");
        case Shape.AcuteConvexProlate:
          return require("./shapeTable8/8-prolate-acute-convex.png");
        case Shape.AcuteConvexOblate:
          return require("./shapeTable8/8-oblate-acute-convex.png");
        case Shape.ConvexoPlaneSpheroidal:
          return require("./shapeTable8/8-spheroidal-convexo-plane.png");
        case Shape.ConvexoPlaneProlate:
          return require("./shapeTable8/8-prolate-convexo-plane.png");
        case Shape.ConvexoPlaneOblate:
          return require("./shapeTable8/8-oblate-convexo-plane.png");
        case Shape.ConvexoConcaveSpheroidal:
          return require("./shapeTable8/8-spheroidal-convexo-concave.png");
        case Shape.ConvexoConcaveProlate:
          return require("./shapeTable8/8-prolate-convexo-concave.png");
        case Shape.ConvexoConcaveOblate:
          return require("./shapeTable8/8-oblate-convexo-concave.png");
        case Shape.ConvexoAcuteSpheroidal:
          return require("./shapeTable8/8-spheroidal-convexo-acute.png");
        case Shape.ConvexoAcuteProlate:
          return require("./shapeTable8/8-prolate-convexo-acute.png");
        case Shape.ConvexoAcuteOblate:
          return require("./shapeTable8/8-oblate-convexo-acute.png");
      }
      break;
  }
};
