//TO DO:
// enum Class {
//     Sac = "sac",
//     Polypli = "polypli",
//     Inap = "inap",
//     Uy = "uy",
//     C1 = "c1"
// }

export interface PageProps {
  next?: () => void;
  back?: () => void;
}

export enum ClassType {
  Sac = "Saccate",
  Polypli = "Polyplicate",
  Inap = "Inaperturate",
  Ctricho = "Trichotomocolpate",
  C1 = "Monocolpate",
  C2 = "Dicolpate",
  C3 = "Tricolpate",
  Cstp = "Stephanocolpate",
  Cperi = "Pericolpate",
  P1 = "Monoporate",
  P2 = "Diporate",
  P3 = "Triporate",
  Pstp = "Stephanoporate",
  Pperi = "Periporate",
  C2P2 = "Dicolporate",
  C3P3 = "Tricolporate",
  CPstp = "Stephanocolporate",
  CPperi = "Pericolporate",
  Csyn = "Syncolpate",
  Chet = "Heterocolpate",
  Dy = "Dyad",
  Tetr = "Tetrad",
  Poly = "Polyad",
  L0 = "Alete",
  L1 = "Monolete",
  L2 = "Trilete",
}

export type ClassProperties =
  | ColpiProperties
  | PoriProperties
  | ColpiPoriProperties
  | LaesuraeProperties;

export enum ClassPropertyGroup {
  Colpi,
  Pori,
  ColpiPori,
  Laesurae,
}

export enum SizeClass {
  Perminuta = "very small ( <10 µm)",
  MinutaMinus = "small (10-17.5 µm)",
  MinutaPlus = "small (17.5-25 µm)",
  Media = "medium (25-50 µm)",
  Magna = "large (50-100 µm)",
  Permagna = "very large ( >100 µm)",
}

export enum StructureType {
  Intec = "intec",
  Tec = "tec",
  Semitec = "semitec",
}

export enum ColumellaeForm {
  colIncer = "incertae; Columellae absent or not resolved",
  colSimpl = "Columellae simple, unbranched",
  colDig = "Columellae distally branched",
  colConj = "Columellae distally united in groups",
}

export enum ColumellaeShape {
  colCircular = "circular",
  colPolygonal = "polygonal",
  colIrregular = "irregular",
}

export enum ExineStratification {
  stratIncer = "Stratification is not clear",
  stratZero = "No resolvable stratification; Homogenous wall",
  stratOne = "1",
  stratTwo = "2",
  stratThree = "3",
}
export enum StructureIndex {
  Small = "small (<.05)",
  Medium = "medium (.05-.1)",
  Large = "large (.1-.25)",
  VLarge = "vLarge (>.25)",
}

export enum EktexineEndexineRatio {
  Incer = "not distinguishable",
  Small = "small",
  Medium = "medium",
  Large = "large",
  VLarge = "vLarge",
}
export enum PerisporeExosporeRatio {
  Zero = "indistinguishable",
  Decid = "deciduous",
  Small = "small",
  Med = "med",
  Large = "large",
  VLarge = "vLarge",
}

enum StratificationLayerType {
  Unclear = "unclear",
  Homogenous = "homogenous",
}

interface StructureFeatures {
  stratificationLayers?: StratificationLayerType | number;
  cavea?: boolean;
  ektEndRatio?: EktexineEndexineRatio;
  perExRatio?: PerisporeExosporeRatio;
  arci?: boolean;
  endocracks?: boolean;
  cingulum?: boolean;
}

export enum View {
  Polar = "Polar",
  Equatorial = "Equatorial",
  EquatorialLongitudinal = "Equatorial Longitudinal",
  EquatorialTransverse = "Equatorial Transverse",
}

export enum Symmetry {
  RadialHomopolar = "Radial Homopolar",
  RadialHeteropolar = "Radial Heteropolar",
  Bilateral = "Bilateral",
  BilateralHomopolar = "Bilateral Homopolar",
  BilateralHeteropolar = "Bilateral Heteropolar",
}

// enum PolarRadialShape {
//   Circular = "circular",
//   Angular = "angular",
//   InterAngular = "inter-angular",
//   SemiAngular = "semi-angular",
//   InterSemiAngular = "inter-semi-angular",
//   Subangular = "subangular",
//   InterSubangular = "inter-subangular",
//   Lobate = "lobate",
//   InterLobate = "inter-lobate",
//   SemiLobate = "semi-lobate",
//   InterSemiLobate = "inter-semi-lobate",
//   Polygonal = "polygonal",
//   InterPolygonal = "inter-polygonal",
// }

// enum EquatorialRadialShape {
//   Circular = "circular",
//   Elliptical = "elliptical",
//   Rhomboidal = "rhomboidal",
//   Apiculate = "apiculate",
//   Rectangular = "rectangular",
//   ConstrictedElliptical = "constrictedElliptical",
//   ConstrictedRectangular = "constrictedRectangular",
// }

// type Shape = EquatorialRadialShape | PolarRadialShape;

// enum Convexity {
//   Spheroidal,
//   Prolate,
//   Oblate,
// }

export interface ColumellaeProperties {
  form: string;
  shape: string;
  arrangement: string;
}

// type StructureIndex = "small" | "med" | "large" | "vLarge";

export interface Entry {
  class?: ClassType;
  classProperties?: ClassProperties;
  structure?: StructureType;
  columellae?: ColumellaeForm;
  columellaeShape?: ColumellaeShape;
  exineStratification?: ExineStratification;
  arci?: boolean;
  endocracks?: boolean;
  cingulum?: boolean;
  maxExineThickness?: number;
  minExineThickness?: number;
  maxPerisporeThickness?: number;
  tectumThickness?: number;
  structureIndex?: StructureIndex;
  structureFeatures?: StructureFeatures;
  size?: SizeClass;
  view?: View;
  symmetry?: Symmetry;
  shape?: Shape;
  sculpture?: any;
}

export enum ColpiForm {
  Ca1 = "Ca1",
  Ca1a = "Ca1a",
}

export enum ColpiDelimitation {
  Cb1a = "Cb1a",
  Cb1b = "Cb1b",
}

export enum ColpiStructure {
  Cy1 = "Cy1",
  Cy2 = "Cy2",
  Cy3 = "Cy3",
}

export interface ColpiProperties {
  group: ClassPropertyGroup.Colpi;
  form?: ColpiForm;
  structure?: ColpiStructure;
  delimitation?: ColpiDelimitation;
}

export interface PoriProperties {
  group: ClassPropertyGroup.Pori;
  form?: "doThis" | "andThis";
  delimitation?: "rasdr" | "yes";
  structure?: "one" | "two";
}

export interface ColpiPoriProperties {
  group: ClassPropertyGroup.ColpiPori;
  colpiProperties?: ColpiProperties;
  poriProperties?: PoriProperties;
}

export interface LaesuraeProperties {
  group: ClassPropertyGroup.Laesurae;
  index?: "oneHundred";
  form?: "";
  delimitation?: "resrt";
  length?: number;
}

export type ShapeTableNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export enum Shape {
  //Shape Table 1 shapes
  Circular = "circular",
  Angular = "angular",
  InterAngular = "inter-angular",
  SemiAngular = "semi-angular",
  InterSemiAngular = "inter-semi-angular",
  Subangular = "subangular",
  InterSubangular = "inter-subangular",
  Lobate = "lobate",
  InterLobate = "inter-lobate",
  SemiLobate = "semi-lobate",
  InterSemiLobate = "inter-semi-lobate",
  Polygonal = "polygonal",
  InterPolygonal = "inter-polygonal",
  //Shape Table 2 shapes
  CircularSpheroidal = "circular-spheroidal",
  EllipticalProlate = "elliptical-prolate",
  EllipticalOblate = "elliptical-oblate",
  RhomboidalSpheroidal = "rhomboidal-spheroidal",
  RhomboidalProlate = "rhomboidal-prolate",
  RhomboidalOblate = "rhomboidal-oblate",
  ApiculateSpheroidal = "apiculate-spheroidal",
  ApiculateProlate = "apiculate-prolate",
  ApiculateOblate = "apiculate-oblate",
  RectangularSpheroidal = "rectangular-spheroidal",
  RectangularProlate = "rectangular-prolate",
  RectangularOblate = "rectangular-oblate",
  ConstrictedEllipticalSpheroidal = "constricted-elliptical-spheroidal",
  ConstrictedEllipticalProlate = "constricted-elliptical-prolate",
  ConstrictedEllipticalOblate = "constricted-elliptical-oblate",
  ConstrictedRectangularSpheroidal = "constricted-rectangular-spheroidal",
  ConstrictedRectangularProlate = "constricted-rectangular-prolate",
  ConstrictedRectangularOblate = "constricted-rectangular-oblate",
  CompressedEllipticalSpheroidal = "compressed-elliptical-spheroidal",
  CompressedEllipticalProlate = "compressed-elliptical-prolate",
  CompressedEllipticalOblate = "compressed-elliptical-oblate",
  DepressedEllipticalSpheroidal = "depressed-elliptical-spheroidal",
  DepressedEllipticalProlate = "depressed-elliptical-prolate",
  DepressedEllipticalOblate = "depressed-elliptical-oblate",
  //Shape Table 3 shapes
  BiconvexSpheroidal = "biconvex-spheroidal",
  BiconvexOblate = "biconvex-oblate",
  PlanoConvexSpheroidal = "plano-convex-spheroidal",
  PlanoConvexOblate = "plano-convec-oblate",
  ConcavoConvexSpheroidal = "concavo-convex-spheroidal",
  ConcavoConvexOblate = "concavo-convex-oblate",
  ConvexoPlaneSpheroidal = "convexo-plane-spheroidal",
  ConvexoPlaneOblate = "convexo-plane-oblate",
  ConvexoConcaveSpheroidal = "convexo-concave-spheroidal",
  ConvexoConcaveOblate = "convexo-concave-oblate",
  ConvexoAcuteSpheroidal = "convexo-acute-spheroidal",
  ConvexoAcuteOblate = "convexo-acute-oblate",
  RectangularAcuteSpheroidal = "rectangular-acute-spheroidal",
  RectangularAcuteOblate = "rectangular-acute-oblate",
  //Shape Table 4 shapes
  Elliptical = "elliptical",
  Rhomboidal = "rhomboidal",
  Apiculate = "apiculate",
  Rectangular = "rectangular",
  ConstrictedElliptical = "constricted-elliptical",
  ConstrictedRectangular = "constricted-rectangular",
  CompressedElliptical = "compressed-elliptical",
  DepressedElliptical = "depressed-elliptical",
  //Shape Table 5, 6, & 7 shapes ARE ALL in previous shape tables
  //Shape Table 8 shapes(some previously done)
  BiconvexProlate = "biconvex-prolate",
  PlanoConvexProlate = "plano-convex-prolate",
  ConcavoConvexProlate = "concavo-convex-prolate",
  AcuteConvexSpheroidal = "acute-convex-spheroidal",
  AcuteConvexProlate = "acute-convex-prolate",
  AcuteConvexOblate = "acute-convex-oblate",
  ConvexoPlaneProlate = "convexo-plane-prolate",
  ConvexoConcaveProlate = "convexo-concave-prolate",
  ConvexoAcuteProlate = "convexo-acute-prolate",
}

// const newColpiEntry: Colpi = {
//     class: Class.C1,
//     view: View.Equatorial,
//     structure: {
//         type: StructureType.Semitec,
//         columella:{
//             form: "round",
//             shape: "dodecahedron",
//             arrangement: "vertical"
//         },
//     },
//     form: "bigForm",
//     delimitation: "del",
//     colpiStructure: "cStructure",
//     pollenAperture: "pAperature",
//     pollenMeasurements: "reallyBig"
// }

// STEP 0: ENTER A CLASS TYPE
//      STEP 0_a: IF NECESSARY, ENTER EXTRA CLASS PROPERTIES
// STEP 1: ENTER A STRUCTURE TYPE
//      STEP 1_a: IF NECESSARY, ENTER COLUMELLAE PROPERTIES
//      STEP 1_b: ENTER STRUCTURE INDEX
//      STEP 1_c: ENTER STRUCTURE FEATURES
// STEP 2: DETERMINE THE SHAPE
//      STEP 2_a: CHOOSE A VIEW
//      STEP 2_b: CHOOSE A SYMMETRY
//      STEP 2_c: BASED ON THE VIEW & SYMMETRY, CHOOSE A SHAPE FROM A TABLE
// STEP 3: ENTER THE SIZE CLASS
//      STEP 3_a: ENTER THE SIZE PROPERTIES

// const EntryForm = (): Entry => {
//   let classType: ClassType,
//     classProperties: ClassProperties,
//     structureType: StructureType,
//     columellaeProperties: ColumellaeProperties | undefined,
//     structure: StructureType,
//     structureIndex: StructureIndex,
//     structureFeatures: StructureFeatures,
//     view: View,
//     symmetry: Symmetry,
//     shapeTable: ShapeTable,
//     shape: Shape,
//     sizeProperties;

//   // pretend there's a form here to pick the class
//   classType = ClassType.C1;

//   // new form (or table) to pick the structure type
//   structure = StructureType.Intec;

//   switch (structure) {
//     case StructureType.Intec:
//       // move on
//       columellaeProperties = undefined;
//       break;
//   }

//   // enter the structure index
//   structureIndex = "vLarge";

//   //structure features
//   structureFeatures = {
//     arci: true,
//     cavea: false,
//     cingulum: true,
//     ektEndRatio: EktexineEndexineRatio.vLarge,
//     endocracks: false,
//     perExRatio: PerisporeExosporeRatio.small,
//     stratificationLayers: 420,
//   };

//   const getShapeTable = (
//     view: View,
//     symmetry: Symmetry
//   ): ShapeTable | undefined => {
//     let shapeTable: ShapeTable | undefined;

//     switch (view) {
//       case View.Equatorial:
//         if (symmetry === Symmetry.RadialHeteropolar) {
//           shapeTable = 3;
//         } else if (symmetry === Symmetry.BilateralHomopolar) {
//           shapeTable = 2;
//         }
//         break;
//       case View.Polar:
//         if (symmetry === Symmetry.RadialHomopolar) {
//           shapeTable = 1;
//         } else if (symmetry === Symmetry.Bilateral) {
//           shapeTable = 4;
//         }
//         break;
//       default:
//         shapeTable = undefined;
//     }

//     return shapeTable;
//   };

//   // choose view
//   view = View.Polar;

//   // choose symmetry based on view
//   // switch(view) {
//   // case View.Equatorial:
//   // present RadioHeteroPolar | BilateralHomopolar
//   // case View.Polar:
//   // present RadialHomopolar | Bilateral
//   // }

//   symmetry = Symmetry.Bilateral;

//   // shape table
//   shapeTable = getShapeTable(view, symmetry) as ShapeTable;

//   // from the shape table, choose a shape (click the right picture)
//   shape = EquatorialRadialShape.Apiculate;

//   return {
//     class: classType,
//     classProperties: classProperties,
//     columellaeProperties: columellaeProperties,
//     structure: structure,
//     structureIndex: structureIndex,
//     structureFeatures: structureFeatures,
//     view: view,
//     symmetry: symmetry,
//     shape: shape,
//   };
// };
