import React, { useReducer } from "react";
import styled, { createGlobalStyle } from "styled-components";

import { reducer, EntryContext } from "./context";

import Pages from "./Pages";

const GlobalStyle = createGlobalStyle`
  body {
    background: oldlace;
    color: forestgreen;

  }
`;

const MainContainer = styled.div`
  padding: 20px;
  height: 100%;
  font-family: "Domine";
  background: oldlace;

  h1 {
    color: forestgreen;
    border-bottom: 10px groove forestgreen;
    padding: 10px;
  }

  select {
    font-weight: bold;
    color: slategray;
    height: 30px;
    padding: 5px;
  }

  button {
    font-family: "Domine";
    font-size: 20px;
    height: 30px;
    padding: 20px;
    display: flex;
    align-items: center;
    background: transparent;
    border: 4px groove forestgreen;
    color: forestgreen;

    cursor: pointer;

    :hover {
      background: mediumseagreen;
    }
  }
`;

function App() {
  const [entry, dispatch] = useReducer(reducer, {});

  return (
    <EntryContext.Provider value={{ entry, dispatch }}>
      <GlobalStyle />
      <MainContainer>
        <Pages />
      </MainContainer>
    </EntryContext.Provider>
  );
}

export default App;
