import React from "react";
import styled from "styled-components";

import { ActionType, EntryContext } from "./context";
import { getShapeImage } from "./images";

import { View, Symmetry, ShapeTableNumber, Shape } from "./types";

const ShapeTableData = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: oldlace;
  border: ${(props) => (props.selected ? "3px solid red" : "1px solid black")};
  padding: 5px;
  cursor: pointer;

  img {
    height: 133px;
  }
`;

const ShapeTableImage = styled.img<{ selected?: boolean }>`
  border: ${(props) => (props.selected ? "3px solid red" : "1px solid black")};
  cursor: pointer;
`;

const ShapeTable = styled.table`
  img {
    height: 133px;
  }
  td {
    background: white;
  }
`;

function ShapeComponent(props: { table: ShapeTableNumber; shape: Shape }) {
  const { entry, dispatch } = React.useContext(EntryContext);

  const imageFile = getShapeImage(props.table, props.shape);

  return (
    <td>
      <ShapeTableData
        onClick={() => dispatch({ type: ActionType.SetShape, data: { shape: props.shape } })}
        selected={entry.shape === props.shape}
      >
        <h3>{props.shape}</h3>
        <ShapeTableImage selected={entry.shape === Shape.CircularSpheroidal} src={imageFile} />
      </ShapeTableData>
    </td>
  );
}

export default function ShapeTableSelector() {
  const { entry, dispatch } = React.useContext(EntryContext);

  if (!(entry.view && entry.symmetry)) return null;

  const getShapeTable = (view: View, symmetry: Symmetry): ShapeTableNumber | undefined => {
    if (view === View.Polar) {
      if (symmetry === Symmetry.RadialHomopolar) {
        return 1;
      } else if (symmetry === Symmetry.Bilateral) {
        return 4;
      }
    } else if (view === View.Equatorial) {
      if (symmetry === Symmetry.RadialHomopolar) {
        return 2;
      } else if (symmetry === Symmetry.RadialHeteropolar) {
        return 3;
      }
    } else if (view === View.EquatorialLongitudinal) {
      if (symmetry === Symmetry.BilateralHomopolar) {
        return 5;
      } else if (symmetry === Symmetry.BilateralHeteropolar) {
        return 7;
      }
    } else if (view === View.EquatorialTransverse) {
      if (symmetry === Symmetry.BilateralHomopolar) {
        return 6;
      } else if (symmetry === Symmetry.BilateralHeteropolar) {
        return 8;
      }
    }
  };

  const currentShapeTable =
    entry.view && entry.symmetry ? getShapeTable(entry.view, entry.symmetry) : undefined;

  if (currentShapeTable === 1) {
    return (
      <table>
        <thead>
          <tr>
            <th>Term & Example in Polar View</th>
            <th>Term & Example in Polar View</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ShapeComponent table={1} shape={Shape.Circular} />
            <ShapeComponent table={1} shape={Shape.Lobate} />
          </tr>
          <tr>
            <ShapeComponent table={1} shape={Shape.Angular} />
            <ShapeComponent table={1} shape={Shape.InterLobate} />
          </tr>
          <tr>
            <ShapeComponent table={1} shape={Shape.InterAngular} />
            <ShapeComponent table={1} shape={Shape.SemiLobate} />
          </tr>
          <tr>
            <ShapeComponent table={1} shape={Shape.SemiAngular} />
            <ShapeComponent table={1} shape={Shape.InterSemiLobate} />
          </tr>
          <tr>
            <ShapeComponent table={1} shape={Shape.InterSemiAngular} />
            <ShapeComponent table={1} shape={Shape.Polygonal} />
          </tr>
          <tr>
            <ShapeComponent table={1} shape={Shape.Subangular} />
            <ShapeComponent table={1} shape={Shape.InterPolygonal} />
          </tr>
          <tr>
            <ShapeComponent table={1} shape={Shape.InterSubangular} />
          </tr>
        </tbody>
      </table>
    );
  } else if (currentShapeTable === 2) {
    return (
      <ShapeTable>
        <thead>
          <tr>
            <th>Equatorial View: </th>
            <th>Spheroidal</th>
            <th>Prolate</th>
            <th>Oblate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Circular: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.CircularSpheroidal}
                alt="shape"
                src={getShapeImage(2, Shape.CircularSpheroidal)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.CircularSpheroidal } })
                }
              />
            </td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
          </tr>
          <tr>
            <th>Elliptical: </th>
            <td>Not Applicable</td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.EllipticalProlate}
                alt="shape"
                src={getShapeImage(2, Shape.EllipticalProlate)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.EllipticalProlate } })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.EllipticalOblate}
                alt="shape"
                src={getShapeImage(2, Shape.EllipticalOblate)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.EllipticalOblate } })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Rhomboidal: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.RhomboidalSpheroidal}
                alt="shape"
                src={getShapeImage(2, Shape.RhomboidalSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.RhomboidalSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.RhomboidalProlate}
                alt="shape"
                src={getShapeImage(2, Shape.RhomboidalProlate)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.RhomboidalProlate } })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.RhomboidalOblate}
                alt="shape"
                src={getShapeImage(2, Shape.RhomboidalOblate)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.RhomboidalOblate } })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Apiculate: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ApiculateSpheroidal}
                alt="shape"
                src={getShapeImage(2, Shape.ApiculateSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ApiculateSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ApiculateProlate}
                alt="shape"
                src={getShapeImage(2, Shape.ApiculateProlate)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.ApiculateProlate } })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ApiculateOblate}
                alt="shape"
                src={getShapeImage(2, Shape.ApiculateOblate)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.ApiculateOblate } })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Rectangular: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.RectangularSpheroidal}
                alt="shape"
                src={getShapeImage(2, Shape.RectangularSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.RectangularAcuteSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.RectangularProlate}
                alt="shape"
                src={getShapeImage(2, Shape.RectangularProlate)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.RectangularProlate } })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.RectangularOblate}
                alt="shape"
                src={getShapeImage(2, Shape.RectangularOblate)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.RectangularOblate } })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Constricted Elliptical: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConstrictedEllipticalSpheroidal}
                alt="shape"
                src={getShapeImage(2, Shape.ConstrictedEllipticalSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConstrictedEllipticalSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConstrictedEllipticalProlate}
                alt="shape"
                src={getShapeImage(2, Shape.ConstrictedEllipticalProlate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConstrictedEllipticalProlate },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConstrictedEllipticalOblate}
                alt="shape"
                src={getShapeImage(2, Shape.ConstrictedEllipticalOblate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConstrictedEllipticalOblate },
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Constricted Rectangular: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConstrictedRectangularSpheroidal}
                alt="shape"
                src={getShapeImage(2, Shape.ConstrictedRectangularSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConstrictedRectangularSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConstrictedRectangularProlate}
                alt="shape"
                src={getShapeImage(2, Shape.ConstrictedRectangularProlate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConstrictedRectangularProlate },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConstrictedRectangularOblate}
                alt="shape"
                src={getShapeImage(2, Shape.ConstrictedRectangularOblate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConstrictedRectangularOblate },
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Compressed Elliptical: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.CompressedEllipticalSpheroidal}
                alt="shape"
                src={getShapeImage(2, Shape.CompressedEllipticalSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.CompressedEllipticalSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.CompressedEllipticalProlate}
                alt="shape"
                src={getShapeImage(2, Shape.CompressedEllipticalProlate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.CompressedEllipticalProlate },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.CompressedEllipticalOblate}
                alt="shape"
                src={getShapeImage(2, Shape.CompressedEllipticalOblate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.CompressedEllipticalOblate },
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Depressed Elliptical: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.DepressedEllipticalSpheroidal}
                alt="shape"
                src={getShapeImage(2, Shape.DepressedEllipticalSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.DepressedEllipticalSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.DepressedEllipticalProlate}
                alt="shape"
                src={getShapeImage(2, Shape.DepressedEllipticalProlate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.DepressedEllipticalProlate },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.DepressedEllipticalOblate}
                alt="shape"
                src={getShapeImage(2, Shape.DepressedEllipticalOblate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.DepressedEllipticalOblate },
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </ShapeTable>
    );
  } else if (currentShapeTable === 3) {
    return (
      <ShapeTable>
        <thead>
          <tr>
            <th>Equatorial View: </th>
            <th>Spheroidal</th>
            <th>Oblate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Biconvex: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.BiconvexSpheroidal}
                alt="shape"
                src={getShapeImage(3, Shape.BiconvexSpheroidal)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.BiconvexSpheroidal } })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.BiconvexOblate}
                alt="shape"
                src={getShapeImage(3, Shape.BiconvexOblate)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.BiconvexOblate } })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Plano-Convex: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.PlanoConvexSpheroidal}
                alt="shape"
                src={getShapeImage(3, Shape.PlanoConvexSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.PlanoConvexSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.PlanoConvexOblate}
                alt="shape"
                src={getShapeImage(3, Shape.PlanoConvexOblate)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.PlanoConvexOblate } })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Concavo-Convex: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConcavoConvexSpheroidal}
                alt="shape"
                src={getShapeImage(3, Shape.ConcavoConvexSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConcavoConvexSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConcavoConvexOblate}
                src={getShapeImage(3, Shape.ConcavoConvexOblate)}
                alt="shape"
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConcavoConvexOblate },
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Convexo-Plane: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoPlaneSpheroidal}
                src={getShapeImage(3, Shape.ConvexoPlaneSpheroidal)}
                alt="shape"
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoPlaneSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoPlaneOblate}
                src={getShapeImage(3, Shape.ConvexoPlaneOblate)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.ConvexoPlaneOblate } })
                }
                alt="shape"
              />
            </td>
          </tr>
          <tr>
            <th>Convexo-Concave: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoConcaveSpheroidal}
                src={getShapeImage(3, Shape.ConvexoConcaveSpheroidal)}
                alt="shape"
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoConcaveSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoConcaveOblate}
                src={getShapeImage(3, Shape.ConvexoConcaveOblate)}
                alt="shape"
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoConcaveOblate },
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Convexo-Acute: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoAcuteSpheroidal}
                alt="shape"
                src={getShapeImage(3, Shape.ConvexoAcuteSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoAcuteSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoAcuteOblate}
                alt="shape"
                src={getShapeImage(3, Shape.ConvexoAcuteOblate)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.ConvexoAcuteOblate } })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Rectangular-Acute: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.RectangularAcuteSpheroidal}
                alt="shape"
                src={getShapeImage(3, Shape.RectangularAcuteSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.RectangularAcuteSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.RectangularAcuteOblate}
                alt="shape"
                src={getShapeImage(3, Shape.RectangularAcuteOblate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.RectangularAcuteOblate },
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </ShapeTable>
    );
  } else if (currentShapeTable === 4) {
    return (
      <table>
        <thead>
          <tr>
            <th>Term & Example in Polar View</th>
            <th>Term & Example in Polar View</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ShapeComponent table={4} shape={Shape.Elliptical} />
            <ShapeComponent table={4} shape={Shape.ConstrictedElliptical} />
          </tr>
          <tr>
            <ShapeComponent table={4} shape={Shape.Rhomboidal} />
            <ShapeComponent table={4} shape={Shape.ConstrictedRectangular} />
          </tr>
          <tr>
            <ShapeComponent table={4} shape={Shape.Apiculate} />
            <ShapeComponent table={4} shape={Shape.CompressedElliptical} />
          </tr>
          <tr>
            <ShapeComponent table={4} shape={Shape.Rectangular} />
            <ShapeComponent table={4} shape={Shape.DepressedElliptical} />
          </tr>
        </tbody>
      </table>
    );
  } else if (currentShapeTable === 5) {
    return (
      <table>
        <thead>
          <tr>
            <th>Term & Example in Equatorial Longitudinal View</th>
            <th>Term & Example in Equatorial Longitudinal View</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ShapeComponent table={5} shape={Shape.Circular} />
            <ShapeComponent table={5} shape={Shape.Rectangular} />
          </tr>
          <tr>
            <ShapeComponent table={5} shape={Shape.Elliptical} />
            <ShapeComponent table={5} shape={Shape.CompressedElliptical} />
          </tr>
          <tr>
            <ShapeComponent table={5} shape={Shape.Rhomboidal} />
            <ShapeComponent table={5} shape={Shape.DepressedElliptical} />
          </tr>
        </tbody>
      </table>
    );
  } else if (currentShapeTable === 6) {
    return (
      <ShapeTable>
        <thead>
          <tr>
            <th>Equatorial Transverse View: </th>
            <th>Spheroidal</th>
            <th>Prolate</th>
            <th>Oblate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Circular: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.CircularSpheroidal}
                src={getShapeImage(6, Shape.CircularSpheroidal)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.CircularSpheroidal } })
                }
              />
            </td>
            <td>Not Applicable</td>
            <td>Not Applicable</td>
          </tr>
          <tr>
            <th>Elliptical: </th>
            <td>Not Applicable</td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.EllipticalProlate}
                src={getShapeImage(6, Shape.EllipticalProlate)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.EllipticalProlate } })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.EllipticalOblate}
                src={getShapeImage(6, Shape.EllipticalOblate)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.EllipticalOblate } })
                }
              />
            </td>
          </tr>
        </tbody>
      </ShapeTable>
    );
  } else if (currentShapeTable === 7) {
    return (
      <ShapeTable>
        <thead>
          <tr>
            <th>Equatorial Longitudinal View: </th>
            <th>Spheroidal</th>
            <th>Oblate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Biconvex: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.BiconvexSpheroidal}
                src={getShapeImage(7, Shape.BiconvexSpheroidal)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.BiconvexSpheroidal } })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.BiconvexOblate}
                src={getShapeImage(7, Shape.BiconvexOblate)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.BiconvexOblate } })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Plano-Convex: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.PlanoConvexSpheroidal}
                src={getShapeImage(7, Shape.PlanoConvexSpheroidal)}
                alt="shape"
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.PlanoConvexSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.PlanoConvexOblate}
                src={getShapeImage(7, Shape.PlanoConvexOblate)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.PlanoConvexOblate } })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Concavo-Convex: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConcavoConvexSpheroidal}
                src={getShapeImage(7, Shape.ConcavoConvexSpheroidal)}
                alt="shape"
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConcavoConvexSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConcavoConvexOblate}
                src={getShapeImage(7, Shape.ConcavoConvexOblate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConcavoConvexOblate },
                  })
                }
                alt="shape"
              />
            </td>
          </tr>
          <tr>
            <th>Convexo-Plane: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoPlaneSpheroidal}
                src={getShapeImage(7, Shape.ConvexoPlaneSpheroidal)}
                alt="shape"
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoPlaneSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoPlaneOblate}
                src={getShapeImage(7, Shape.ConvexoPlaneOblate)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.ConvexoPlaneOblate } })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Convexo-Concave: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoConcaveSpheroidal}
                src={getShapeImage(7, Shape.ConvexoConcaveSpheroidal)}
                alt="shape"
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoConcaveSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoConcaveOblate}
                src={getShapeImage(7, Shape.ConvexoConcaveOblate)}
                alt="shape"
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoConcaveOblate },
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </ShapeTable>
    );
  } else if (currentShapeTable === 8) {
    return (
      <ShapeTable>
        <thead>
          <tr>
            <th>Equatorial Transverse View: </th>
            <th>Spheroidal</th>
            <th>Prolate</th>
            <th>Oblate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Biconvex: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.BiconvexSpheroidal}
                src={getShapeImage(8, Shape.BiconvexSpheroidal)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.BiconvexSpheroidal } })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.BiconvexProlate}
                src={getShapeImage(8, Shape.BiconvexProlate)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.BiconvexProlate } })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.BiconvexOblate}
                src={getShapeImage(8, Shape.BiconvexOblate)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.BiconvexOblate } })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Plano-Convex: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.PlanoConvexSpheroidal}
                src={getShapeImage(8, Shape.PlanoConvexSpheroidal)}
                alt="shape"
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.PlanoConvexSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.PlanoConvexProlate}
                src={getShapeImage(8, Shape.PlanoConvexProlate)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.PlanoConvexProlate } })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.PlanoConvexOblate}
                src={getShapeImage(8, Shape.PlanoConvexOblate)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.PlanoConvexOblate } })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Concavo-Convex: : </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConcavoConvexSpheroidal}
                src={getShapeImage(8, Shape.ConcavoConvexSpheroidal)}
                alt="shape"
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConcavoConvexSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConcavoConvexProlate}
                src={getShapeImage(8, Shape.ConcavoConvexProlate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConcavoConvexProlate },
                  })
                }
                alt="shape"
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConcavoConvexOblate}
                src={getShapeImage(8, Shape.ConcavoConvexOblate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConcavoConvexOblate },
                  })
                }
                alt="shape"
              />
            </td>
          </tr>
          <tr>
            <th>Acute-Convex: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.AcuteConvexSpheroidal}
                src={getShapeImage(8, Shape.AcuteConvexSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.AcuteConvexSpheroidal },
                  })
                }
                alt="shape"
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.AcuteConvexProlate}
                src={getShapeImage(8, Shape.AcuteConvexProlate)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.AcuteConvexProlate } })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.AcuteConvexOblate}
                src={getShapeImage(8, Shape.AcuteConvexOblate)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.AcuteConvexOblate } })
                }
              />
            </td>
          </tr>
          <tr>
            <th>Convexo-Plane: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoPlaneSpheroidal}
                src={getShapeImage(8, Shape.ConvexoPlaneSpheroidal)}
                alt="shape"
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoPlaneSpheroidal },
                  })
                }
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoPlaneProlate}
                src={getShapeImage(8, Shape.ConvexoPlaneProlate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoPlaneProlate },
                  })
                }
                alt="shape"
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoPlaneOblate}
                src={getShapeImage(8, Shape.ConvexoPlaneOblate)}
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.ConvexoPlaneOblate } })
                }
                alt="shape"
              />
            </td>
          </tr>
          <tr>
            <th>Convexo-Concave: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoConcaveSpheroidal}
                src={getShapeImage(8, Shape.ConvexoConcaveSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoConcaveSpheroidal },
                  })
                }
                alt="shape"
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoConcaveProlate}
                src={getShapeImage(8, Shape.ConvexoConcaveProlate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoConcaveProlate },
                  })
                }
                alt="shape"
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoConcaveOblate}
                src={getShapeImage(8, Shape.ConvexoConcaveOblate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoConcaveOblate },
                  })
                }
                alt="shape"
              />
            </td>
          </tr>
          <tr>
            <th>Convexo-Acute: </th>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoAcuteSpheroidal}
                src={getShapeImage(8, Shape.ConvexoAcuteSpheroidal)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoAcuteSpheroidal },
                  })
                }
                alt="shape"
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoAcuteProlate}
                src={getShapeImage(8, Shape.ConvexoAcuteProlate)}
                onClick={() =>
                  dispatch({
                    type: ActionType.SetShape,
                    data: { shape: Shape.ConvexoAcuteProlate },
                  })
                }
                alt="shape"
              />
            </td>
            <td>
              <ShapeTableImage
                selected={entry.shape === Shape.ConvexoAcuteOblate}
                src={getShapeImage(8, Shape.ConvexoAcuteOblate)}
                alt="shape"
                onClick={() =>
                  dispatch({ type: ActionType.SetShape, data: { shape: Shape.ConvexoAcuteOblate } })
                }
              />
            </td>
          </tr>
        </tbody>
      </ShapeTable>
    );
  } else {
    return <p>No shape table exists for this view and symmetry</p>;
  }
}
