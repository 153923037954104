import React from "react";
import { Entry } from "./types";

export const EntryContext = React.createContext<{ entry: Entry; dispatch: React.Dispatch<any> }>({
  entry: {},
  dispatch: () => null,
});

export enum ActionType {
  SetClass,
  SetClassAndSize,
  SetStructure,
  SetShape,
  SetView,
  SetSymmetry,
  SetSculpture,
  ClearEntry,
}

interface Action {
  type: ActionType;
  data?: any;
}

export const reducer = (entry: Entry, action: Action): Entry => {
  switch (action.type) {
    case ActionType.SetClassAndSize:
      return { ...entry, class: action.data.class, size: action.data.size };
    case ActionType.SetStructure:
      return {
        ...entry,
        structure: action.data.structure,
        columellae: action.data.columellae,
        columellaeShape: action.data.columellaeShape,
        exineStratification: action.data.exineStratification,
        arci: action.data.arci,
        endocracks: action.data.endocracks,
        cingulum: action.data.cingulum,
        maxExineThickness: action.data.maxExineThickness,
        minExineThickness: action.data.minExineThickness,
        maxPerisporeThickness: action.data.maxPerisporeThickness,
        tectumThickness: action.data.tectumThickness,
        structureIndex: action.data.structureIndex,
      };
    case ActionType.SetShape:
      return { ...entry, shape: action.data.shape };
    case ActionType.SetSculpture:
      return { ...entry, sculpture: action.data.sculpture };
    case ActionType.SetView:
      return { ...entry, view: action.data.view };
    case ActionType.SetSymmetry:
      return { ...entry, symmetry: action.data.symmetry };
    case ActionType.ClearEntry:
      return {};
    default:
      return entry;
  }
};
