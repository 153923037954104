import React from "react";
import styled from "styled-components";
import { PageProps } from "./types";

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function NavigationButtons(props: PageProps) {
  return (
    <Buttons>
      {props.back && <button onClick={props.back}>Back</button>}
      {props.next && <button onClick={props.next}>{"Next ➟"}</button>}
    </Buttons>
  );
}
