import React from "react";
import styled from "styled-components";
import { ClassType, PageProps, SizeClass } from "./types";

import { EntryContext, ActionType } from "./context";
import NavigationButtons from "./NavigationButtons";

const StyledSelect = styled.select`
  margin-left: 10px;
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px;
  border: 2px forestgreen dashed;
`;

export default function ClassPage(props: PageProps) {
  const [classType, setClassType] = React.useState<ClassType>();
  const [sizeClass, setSizeClass] = React.useState<SizeClass>();

  const { dispatch } = React.useContext(EntryContext);

  const allClasses = Object.values(ClassType);
  const allSizes = Object.values(SizeClass);

  return (
    <div>
      <h1>Class and Size</h1>

      <Section>
        <div>
          <h3>Specimen Class</h3>
          <p>
            An artificial grouping of pollen grains that share a distinctive character, or suite of
            characters.
          </p>
        </div>
        <StyledSelect
          value={classType}
          onChange={(event) => setClassType(event.target.value as ClassType)}
        >
          <option></option>
          {allClasses.map((className, i) => (
            <option key={i}>{className}</option>
          ))}
        </StyledSelect>
      </Section>

      <Section>
        <h3>Specimen Size</h3>
        <StyledSelect
          value={sizeClass}
          onChange={(event) => setSizeClass(event.target.value as SizeClass)}
        >
          <option></option>
          {allSizes.map((size, i) => (
            <option key={i}>{size}</option>
          ))}
        </StyledSelect>
      </Section>
      <NavigationButtons
        next={() => {
          dispatch({
            type: ActionType.SetClassAndSize,
            data: { class: classType, size: sizeClass },
          });
          props.next?.();
        }}
        back={() => props.back?.()}
      />
    </div>
  );
}
